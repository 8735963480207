import React from 'react';
import Button from '@material-ui/core/Button';

import { useRemoveFastTrack } from '../adapters/fasttrack'; 

export default function RimuoviFt({ ft_id, reset }) {

    const mutateRemoveFastTrack = useRemoveFastTrack();

    React.useEffect(() => {
        if (mutateRemoveFastTrack.status === 'success') {
            mutateRemoveFastTrack.reset()
            reset()
        }
    }, [mutateRemoveFastTrack])

    const handleRemove = () => {
        mutateRemoveFastTrack.mutate(ft_id)
    };

    return (
        <Button variant="contained" color="primary" onClick={handleRemove} >
            Cancella e torna all'inizio
        </Button>)
}