import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import InputBase from '@material-ui/core/InputBase';
import { alpha, makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import Autocomplete from '@material-ui/lab/Autocomplete';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import { Link as RouterLink } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';
import AccessibilityNewIcon from '@material-ui/icons/AccessibilityNew';

import { useHistory } from "react-router-dom";

import { useSearchFastTrack } from '../../adapters/fasttrack';

const useStyles = makeStyles((theme) => ({
    title: {
        flexGrow: 1,
        display: 'none',
        color: theme.palette.common.white,
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },
    inputRoot: {
        color: theme.palette.common.white,
    },
    search: {
        borderRadius: theme.shape.borderRadius,
        backgroundColor: alpha(theme.palette.common.white, 0.30),
        '&:hover': {
            backgroundColor: alpha(theme.palette.common.white, 0.35),
        },
        zIndex: 300
    },
    icon: {
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(2),
    },
    accessibilityIcon: {
        color: '#FFFFFF', // or theme.palette.common.white
    },
}));

export default function AppBarFt() {
    const classes = useStyles();

    const history = useHistory();

    const [inputValue, setInputValue] = React.useState('');
    const [options, setOptions] = React.useState([]);

    const { data, refetch } = useSearchFastTrack(inputValue);

    React.useEffect(() => {
        if (inputValue !== '') {
            refetch()
        }
    }, [inputValue])

    React.useEffect(() => {
        if (data) {
            setOptions(data)
        }
    }, [data])

    return (
        <AppBar position="static">
            <Toolbar>
                <RouterLink className={classes.title} to={'/'} style={{ textDecoration: 'none' }}>
                    <Typography className={classes.title} variant="h6" noWrap>
                        Fast Track
                    </Typography>
                </RouterLink>
                <div  >
                    <Autocomplete
                        onChange={(event, value) => {
                            history.push(`/ft/${value.gid}`)
                        }}
                        onInputChange={(event, newValue) => {
                            setInputValue(newValue);
                        }}
                        style={{ width: 300 }}
                        getOptionLabel={(option) => option.ft_name}
                        options={options}
                        renderInput={(params) => (
                            <Paper className={classes.search} ref={params.InputProps.ref}>
                                <IconButton className={classes.iconButton} aria-label="Search" >
                                    <SearchIcon className={classes.inputRoot} />
                                </IconButton>
                                <InputBase
                                    
                                    className={classes.inputRoot}
                                    placeholder='Cerca Fast Track'
                                    type="text"
                                    inputProps={{
                                        ...params.inputProps, 'aria-label': "Search field" }}
                                    InputProps={{
                                        className: classes.input,
                                    }}
                                />
                            </Paper>
                        )}
                    />
                </div>
                {/* Accessibility Declaration Link */}
                <Tooltip title="Dichiarazione di accessibilità">
                    <IconButton
                        className={classes.accessibilityIcon}
                        component={RouterLink}
                        to="/accessibility"
                    >
                        <AccessibilityNewIcon />
                    </IconButton>
                </Tooltip>
            </Toolbar>
        </AppBar>
    );
}
