import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { MapContainer, TileLayer, LayersControl, MapConsumer } from 'react-leaflet';
import LayerSedi from './LayerSedi';
import LayerNodiRete from './LayerNodiRete';

import { useReverseGeocoder } from '../adapters/googlemaps';

import L from 'leaflet';

const { Overlay } = LayersControl

// Posiziono la mappa al centro della regione ER
const position = [44.502470, 11.342667];

const useStyles = makeStyles((theme) => ({
    map: {
        borderRadius: theme.shape.borderRadius
    },
}));



export default function MapSedi({ sedi, setSedeOnMap }) {
    const classes = useStyles();
    const [sedeFromMap, setSedeFromMap] = React.useState({ lat: null, lng: null });

    const { data, refetch } = useReverseGeocoder(sedeFromMap.lat, sedeFromMap.lng)

    React.useEffect(() => {
        if (sedeFromMap.lat && sedeFromMap.lng) refetch();
    }, [sedeFromMap])

    React.useEffect(() => {
        if (data) setSedeOnMap(data)
    }, [data])


    const memoizedMap = React.useMemo(() => <MapContainer
        center={position}
        minZoom={2}
        maxZoom={18}
        zoom={8}
        className={classes.map}
        style={{ height: "50vh" }} >

        <MapConsumer>
            {(map) => {
                map.on("click", function (e) {
                    const { lat, lng } = e.latlng;
                    setSedeFromMap({ lat: lat, lng: lng });
                });
                return null;
            }}
        </MapConsumer>

        <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />

        <LayersControl>
            <Overlay name="Sedi" checked>
                <LayerSedi sedi={sedi}></LayerSedi>
            </Overlay>
            <Overlay name="Nodi Rete" checked>
                <LayerNodiRete sedi={sedi}></LayerNodiRete>
            </Overlay>
        </LayersControl>

    </MapContainer>, [sedi]);

    return (
        <React.Fragment>

            {memoizedMap}

        </React.Fragment>
    );
}
