import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import PinDropIcon from '@material-ui/icons/PinDrop';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import Tooltip from '@material-ui/core/Tooltip';
import Box from '@material-ui/core/Box';

import { makeStyles } from '@material-ui/core/styles';
import { useAutocompleteGeocoder } from '../adapters/googlemaps';

import MapSedi from './MapSedi';

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
  textGrow: {
    flexGrow: 1
  }
}));

export default function AggiungiSedi({ sedi, setSedi }) {
  const classes = useStyles();
  const [value, setValue] = React.useState();
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState([]);
  const [denominazione, setDenominazione] = React.useState('');

  const [sedeOnMap, setSedeOnMap] = React.useState(null);

  const addSede = () => {
    if (value && denominazione) {
      setSedi([{ denominazione: denominazione, ...value }, ...sedi])
      setOptions([])
    }
  }

  const handleChange = (event) => {
    setDenominazione(event.target.value);
  };

  const { data, refetch } = useAutocompleteGeocoder(inputValue);

  React.useEffect(() => {
    if (inputValue !== '') refetch();
  }, [inputValue]);

  React.useEffect(() => {
    if (sedeOnMap) {
      setOptions([sedeOnMap]);
      setValue(sedeOnMap);
      setInputValue(sedeOnMap.formatted_address)
    };
  }, [sedeOnMap]);

  React.useEffect(() => {
    if (data) setOptions(data)
  }, [data]);

  React.useEffect(() => {
    setDenominazione('');
    setInputValue('');
  }, [sedi]);

  const handleDeleteSede = (place_id) => {
    setSedi(sedi.filter(loc => loc.place_id !== place_id))
  };


  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={8}>
        <Box flexGrow={1}>
          <Autocomplete
            id="google-map-demo"
            filterOptions={(x) => x}
            options={options}
            value={value}
            onChange={(event, newValue) => {
              setValue(newValue)
            }}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);
            }}
            renderInput={(params) => (
              <TextField {...params}
                label="Cerca sede per indirizzo oppure aggiungi un punto sulla mappa"
                variant="outlined" fullWidth />
            )}
            getOptionLabel={x => x.formatted_address}
            renderOption={(option) => {
              return (
                <Grid container alignItems="center">
                  <Grid item>
                    <LocationOnIcon className={classes.icon} />
                  </Grid>
                  <Grid item xs>
                    <span style={{ fontWeight: 400 }}>
                      {`${option.formatted_address}`}
                    </span>
                  </Grid>
                </Grid>
              );
            }}
          />
        </Box>
      </Grid>

      <Grid item xs={12} sm={4}>
        <div style={{ width: '100%' }}>
          <Box display="flex">
            <Box flexGrow={1}>
              <TextField value={denominazione} 
                id="denominazione-sede"
                label="Denominazione sede" 
                variant="outlined" fullWidth 
                onChange={handleChange} />
            </Box>
            <Box>
              <Tooltip title="Aggiungi sede alla richiesta">
                <IconButton
                  edge="end"
                  aria-label="add-sede"
                  style={{ marginLeft: 5, marginRight: 3 }}
                  onClick={addSede}>
                  <AddIcon />
                </IconButton>
              </Tooltip> </Box>
          </Box>
        </div>
      </Grid>

      <Grid item xs={12} sm={8}>
      <MapSedi sedi={sedi} setSedeOnMap={setSedeOnMap} />
      </Grid>
      <Grid item xs={12} sm={4}>
        <List dense>
          {sedi.map((sede, index) =>
            <ListItem key={index}>
              <ListItemAvatar>
                <Avatar>
                  <PinDropIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={sede.denominazione}
                secondary={sede.formatted_address}
              />
              <ListItemSecondaryAction>
                <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteSede(sede.place_id)}>
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          )}
        </List>
      </Grid>
    </Grid>
  );
}