import { useQuery, useMutation, useQueryClient } from 'react-query'
import client from './api-client';

async function insertFastTrak(data) {
    const resData = await client("fasttrack/", { body: data })
    return resData
}

export function useInsertFastTrack(data) {
    return useMutation((data) => insertFastTrak(data), {})
}



async function fetchFastTrack(ft_id) {
    const data = await client(`fasttrack/${ft_id}`)
    return data
}

export function useFastTrack(ft_id) {
    return useQuery(['fasttrack', ft_id], () => fetchFastTrack(ft_id), {
        refetchAllOnWindowFocus: false,
        enabled: false
    });
}



async function searchFastTrack(ft_name) {
    const data = await client(`ftsearch/?search=${ft_name}`)
    return data
}

export function useSearchFastTrack(ft_name) {
    return useQuery(['fasttrack', ft_name], () => searchFastTrack(ft_name), {
        refetchAllOnWindowFocus: false,
        enabled: false
    });
}



async function fetchFastTrackTracciati(ft_id) {
    const data = await client(`tracciati/ft_tracciati/?ft_id=${ft_id}`)
    return data
}

export function useFastTrackTracciati(ft_id) {
    return useQuery(['ft_tracciati', ft_id], () => fetchFastTrackTracciati(ft_id));
}

async function removeFastTrak(ft_id) {
    const resData = await client(`fasttrack/${ft_id}/`, {}, 'DELETE')
    return resData
}

export function useRemoveFastTrack(ft_id) {
    const queryClient = useQueryClient()

    return useMutation((ft_id) => removeFastTrak(ft_id), {
        onSuccess: (data, variables, context) => {
            console.log(data, variables, context, ft_id)
            queryClient.invalidateQueries(['fasttrack', variables])
        },
    })
}
