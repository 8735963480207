import React, { useRef } from 'react';
import { FeatureGroup, Popup, Circle } from 'react-leaflet';

export default function LayerNodiRete({ sedi }) {
    const groupRef = useRef();

    const renderNodiReteOnMap = () => {
        return sedi.flatMap((sede) => {
                const { lepida_nodes: { features } } = sede;
                return features.map((lepida_node) => {
                        const { coordinates } = lepida_node.geometry;
                        return <Circle
                            key={lepida_node.id}
                            center={[coordinates[1], coordinates[0]]}
                            radius={20}
                            pathOptions={{ color: 'red', fillColor: 'blue' }} >
                            <Popup>
                                {lepida_node.properties.etichetta}
                            </Popup> </Circle>
                    })
                })
    }

    return (
        <FeatureGroup color="purple" ref={ groupRef } >
            { renderNodiReteOnMap() }
        </FeatureGroup>
    );
}