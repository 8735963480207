import React from 'react';
import { useParams } from "react-router-dom";

import ReportFastTrack from './ReportFastTrack';

export default function Report(props) {
    let { ft_id } = useParams();

    return (
        <ReportFastTrack ft_id={ft_id}></ReportFastTrack>
    );
}
