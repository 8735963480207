import React, { useRef } from 'react';
import { Marker, FeatureGroup, Tooltip, useMap, useMapEvent } from 'react-leaflet';

export default function LayerSedi({ sedi }) {
    const groupRef = useRef();
    const map = useMap();

    React.useEffect(() => {
        const bounds = groupRef.current.getBounds()
        if (bounds.isValid()) {
            map.fitBounds(bounds, { padding: [100, 100] });
        }
    }, [sedi]);

    const renderSediOnMap = () => {
        return sedi.map((sede, index) => {
            const { lat, lng } = sede["geometry"]["location"];
            return <Marker
                key={index}
                position={[lat, lng]} >
                <Tooltip>
                    {sede["formatted_address"]}
                </Tooltip> </Marker>
        })
    }

    return (
        <FeatureGroup color="purple" ref={groupRef} >
            {renderSediOnMap()}
        </FeatureGroup>
    );
}
