import { useQuery } from 'react-query'
import client from './api-client'

const queryConfig = {
    staleTime: 1000 * 60 * 60,
    cacheTime: 1000 * 60 * 60,
}

async function fetchAutocompleteGeocoder(indirizzo) {
    const data = await client(`googlemaps/autocomplete_geocoder/?indirizzo=${indirizzo}`)
    return data
}

export function useAutocompleteGeocoder(indirizzo) {
    return useQuery(['indirizzo'], 
                    () => fetchAutocompleteGeocoder(indirizzo), {
        refetchAllOnWindowFocus: false,
        enabled: false,
        ...queryConfig
    });
}

async function fetchRevereGeocoder(lat, lng) {
    const data = await client(`googlemaps/reverse_geocoder/?lat=${lat}&lng=${lng}`)
    return data
}

export function useReverseGeocoder(lat, lng) {
    return useQuery([lat, lng], 
                    () => fetchRevereGeocoder(lat, lng), {
        refetchAllOnWindowFocus: false,
        enabled: false,
        ...queryConfig
    });
}