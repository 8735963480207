import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import AggiungiSedi from './AggiungiSedi';
import Box from '@material-ui/core/Box';

import Grid from '@material-ui/core/Grid';

import { useInsertFastTrack } from '../adapters/fasttrack';

import ElaboraPercorsi from './ElaboraPercorsi';
import ReportFastTrack from './reportft/ReportFastTrack';
import RimuoviFt from './RimuoviFt';

const useStyles = makeStyles((theme) => ({
    backButton: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        minHeight: 500
    },
    stepper: {
        borderRadius: theme.shape.borderRadius
    },
}));

function getSteps() {
    return ['Aggiungi tutte le sedi della richiesta', 'Nome Fast Track', 'Elabora percorsi'];
}

export default function Richiesta() {
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const steps = getSteps();

    const [sedi, setSedi] = React.useState([]);
    const [ftNome, setFtNome] = React.useState('');

    const [ft, setFt] = React.useState(null);

    const mutateFastTrack = useInsertFastTrack();

    React.useEffect(() => {
        if (activeStep === steps.length - 1 && !ft) 
            mutateFastTrack.mutate({ sedi: sedi, ft_nome: ftNome })
    }, [activeStep])

    React.useEffect(() => {
        if (mutateFastTrack.status === 'success') {
            setFt(mutateFastTrack.data)
            mutateFastTrack.reset()
            setActiveStep(3)
        }
    }, [mutateFastTrack])

    const handleChange = (event) => {
        setFtNome(event.target.value);
    };

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
        setFt(null);
        setSedi([]);
        setFtNome('');
    };

    const getStepContent = React.useMemo(() => {
        switch (activeStep) {
            case 0:
                return <AggiungiSedi sedi={sedi} setSedi={setSedi} />;
            case 1:
                return <div className={classes.instructions}>
                    <TextField label="Nome Fast Track"
                    id="nome-fast-track"
                    variant="outlined"
                    fullWidth
                    onChange={handleChange}
                    value={ftNome} /></div>;
            case 2:
                return <Box display="flex" justifyContent="center" m={1} p={1}><CircularProgress color="secondary" /></Box>;
            case 3:
                return <ElaboraPercorsi ft={ft} />;
            default:
                return 'Unknown stepIndex';
        }
    }, [sedi, ftNome, ft, activeStep])

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Stepper activeStep={activeStep} className={classes.stepper}>
                    {steps.map((label) => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
            </Grid>
            <>
                {activeStep === steps.length ? (
                    <>
                        <Grid item xs={12}>
                            <ReportFastTrack ft_id={ft.gid} />
                        </Grid>
                        <Grid item xs={12}>
                            <Button onClick={handleReset} className={classes.backButton}>Torna all'inizio</Button>
                            <RimuoviFt ft_id={ft.gid} reset={handleReset}/>
                        </Grid>
                    </>
                ) : (
                    <>
                        <Grid item xs={12}>
                            {getStepContent}
                        </Grid>
                        {activeStep !== steps.length - 1 ? <Grid item xs={12}>
                            <Button
                                disabled={activeStep === 0}
                                onClick={handleBack}
                                className={classes.backButton}> Indietro </Button>

                            <Button variant="contained" 
                                color="primary" 
                                onClick={handleNext} 
                                disabled={sedi.length === 0 || (ftNome.length === 0 && activeStep === 1)}>
                                Prosegui
                            </Button>
                        </Grid> : null}
                    </>
                )}
            </>
        </Grid>
    );
}
