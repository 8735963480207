import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { MapContainer, TileLayer, LayersControl } from 'react-leaflet';

import LayerFtSedi from './LayerFtSedi';
import LayerFtTracciati from './LayerFtTracciati';
import LayerFtNodiLepida from './LayerFtNodiLepida';


const { Overlay } = LayersControl

// Posiziono la mappa al centro della regione ER
const position = [44.502470, 11.342667];

const useStyles = makeStyles((theme) => ({
    map: {
        borderRadius: theme.shape.borderRadius
    },
}));


export default function MapFastTrack({ ft_id, ft_sedi }) {
    const classes = useStyles();

    return (
        <React.Fragment>

            <MapContainer preferCanvas={true}
                center={position}
                minZoom={2}
                maxZoom={18}
                zoom={8}
                className={classes.map}
                style={{ height: "50vh" }} >

                <TileLayer
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />

                <LayersControl>
                    <Overlay name="Fast Track Sedi" checked>
                        <LayerFtSedi ft_sedi={ft_sedi}></LayerFtSedi>
                    </Overlay>
                    <Overlay name="Fast Track Tracciati" checked>
                        <LayerFtTracciati ft_id={ft_id}></LayerFtTracciati>
                    </Overlay>
                    <Overlay name="Nodi Lepida" checked>
                        <LayerFtNodiLepida ft_sedi={ft_sedi}></LayerFtNodiLepida>
                    </Overlay>
                </LayersControl>

            </MapContainer>

        </React.Fragment>
    );
}