const localStorageKey = '__app_token__'

function client(endpoint, {body, ...customConfig} = {}, method = 'GET') {
    const token = window.localStorage.getItem(localStorageKey)
    const headers = {'content-type': 'application/json'}
    if (token) {
      headers.Authorization = `Bearer ${token}`
    }
    const config = {
      method: body ? 'POST' : method,
      ...customConfig,
      headers: {
        ...headers,
        ...customConfig.headers,
      },
    }
    if (body) {
      config.body = JSON.stringify(body)
    }
  
    return window
      .fetch(`${process.env.REACT_APP_BACKEND_API_URL}/${endpoint}`, config)
      .then(r => { 
        if (r.ok && r.status !== 204) 
          return r.json()
        else window.localStorage.removeItem(localStorageKey)
      })
}
  
export default client