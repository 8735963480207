import React, { useRef } from 'react';
import { useMap, GeoJSON } from 'react-leaflet';
import { useFastTrackTracciati } from '../../adapters/fasttrack';

export default function LayerFtTracciati({ ft_id }) {
  const groupRef = useRef();
  const map = useMap();

  const { isLoading, data } = useFastTrackTracciati(ft_id)

  const onEachFeature = (feature, layer) => {
    var tooltip = (`Lunghezza: ${feature.properties.lunghezza} m`);
    layer.bindTooltip(tooltip);
    if (feature.properties.tipo === 1) {
      layer.setStyle({ color: 'red' })
    }

    switch(feature.properties.tipo) {
      case 1:
        layer.setStyle({ color: 'orange' })
        break;
      case 2:
        layer.setStyle({ color: 'red' })
        break;
      case 3:
        layer.setStyle({ color: 'green' })
        break;
      default:
        layer.setStyle({ color: 'yellow' })
    }
  };

  return (
    <>
      {data && <GeoJSON data={data} name="Fast Track Tracciati"
        onEachFeature={onEachFeature}
        ref={groupRef} />}
    </>
  );
}
