import React from 'react';
import { find } from 'lodash';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import Alert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';

import { useFastTrack } from '../../adapters/fasttrack';
import DettaglioFt from './DettaglioFt';
import MapFastTrack from './MapFastTrack';
import ReportScaduto from './ReportScaduto';

import NonValidato from './allert-location.png';
import Validato from './location-correct.png';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    customAlert: {
      backgroundColor: '#750000'
    },
  }));
export default function ReportFastTrack({ ft_id }) {
    const classes = useStyles();
    const [scaduto, setScaduto] = React.useState(null);
    const [showValidateAlert, setShowValidateAlert] = React.useState(false);
    const [percentualeScavo, setPercentualeScavo] = React.useState(30);
    const COSTO_SCAVO = parseFloat(process.env.REACT_APP_COSTO_SCAVO_METRO);
    console.log(COSTO_SCAVO)

    const { data, refetch } = useFastTrack(ft_id);

    React.useEffect(() => {
        if (ft_id) refetch()
    }, [ft_id])

    React.useEffect(() => {
        if (data && find(data.fasttracksedi_set.features, { properties: { hidden: true } })) {
            setScaduto(true);
        }

        if (data && find(data.fasttracksedi_set.features, { properties: { validato: false } })) {
            setShowValidateAlert(true);
        } else {
            setShowValidateAlert(false);
        }
    }, [data])

    function getPrezzoScavo(len) {
        // Tipo 2
        return len * COSTO_SCAVO
    }

    function getPrezzoSoloPosa(len) {
        // Tipo 3
        return len * 8
    }

    function getPrezzoNonDefinito(len) {
        // Tipo 1
        const scavo = percentualeScavo / 100
        const posa = 1 - scavo
        return len * (8 * posa + COSTO_SCAVO * scavo)
    }

    function getCostoTracciati(tracciati) {
        return (tracciati
            .reduce((a, b) => {
                if (b.tipo === 2)
                    return a + getPrezzoScavo(parseFloat(b.lunghezza_link))
                if (b.tipo === 3)
                    return a + getPrezzoSoloPosa(parseFloat(b.lunghezza_link))
                if (b.tipo === 1)
                    return a + getPrezzoNonDefinito(parseFloat(b.lunghezza_link))
            }, 0) + 3000).toFixed(2)
    }

    return (
        <>
            {data ? (!scaduto ? <Grid container spacing={3}>
                {showValidateAlert && <Grid item xs={12}>
                    <Alert className={classes.customAlert} variant="filled" severity="error" onClose={() => { setShowValidateAlert(false) }}>Fast Track contiene sedi non validate! In questo caso la stima economica è poco affidabile.</Alert>
                </Grid>}
                <Grid item xs={12}>
                    <Typography variant="h4" >
                        {`${data.ft_name}`}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={9}>
                    <MapFastTrack ft_id={ft_id} ft_sedi={data.fasttracksedi_set} />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <List dense>
                        {data.fasttracksedi_set.features.map((sede, index) =>
                        <>
                            <ListItem key={index}>
                                <ListItemAvatar>
                                    <Avatar style={{ background: 'transparent' }}>
                                        <img alt='Validato' src={sede.properties.validato ? Validato : NonValidato} style={{ height: 30 }} />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={`${sede.properties.denominazione}, ${sede.properties.indirizzo}`}
                                    secondary={
                                        <React.Fragment>
                                            <Typography
                                                component="span"
                                                variant="body2"
                                                color="textPrimary"
                                            >{`Costo: ${getCostoTracciati(sede.properties.lunghezza_link)}€`}</Typography>
                                            <br />
                                            {`Lunghezza percorso: ${sede.properties.lunghezza_link.reduce((a, b) => a + parseFloat(b.lunghezza_link), 0).toFixed(2)}m`}
                                        </React.Fragment>
                                    }

                                />
                            </ListItem>
                            { data.fasttracksedi_set.features.length !== index + 1 &&  <Divider variant="inset" component="li" /> }
                        </>
                        )}
                    </List>
                </Grid>
                <Grid item xs={12} sm={9}>
                    <DettaglioFt sedi={data.fasttracksedi_set} percentualeScavo={percentualeScavo} setPercentualeScavo={setPercentualeScavo} />
                </Grid>
            </Grid> : <ReportScaduto ft_name={data.ft_name}></ReportScaduto>) :

                <Grid container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justify="center"><CircularProgress size={50} /></Grid>}
        </>
    );
}