import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';

import { useInsertFastTrack } from '../adapters/fasttrack';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    backButton: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(1),
        minHeight: 500
    },
}));

export default function ElaboraPercorsi({ ft }) {
    const classes = useStyles();

    return (
        <>
            { <div>{ft.ft_name}</div> }
        </>
    );
}
