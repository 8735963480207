import React from 'react';
import './App.css';
import {
    Switch,
    Route
} from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Container from "@material-ui/core/Container";

import { createTheme, ThemeProvider } from '@material-ui/core/styles';

import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from "react-query/devtools";

import Richiesta from './components/Richiesta';
import Report from './components/reportft/Report';
import AppBarFt from './components/appbar/AppBarFt';
import Accessibility from './components/Accessability';

const queryClient = new QueryClient();

const outerTheme = createTheme({
    palette: {
        primary: {
            main: '#203A58',
            light: '#dad9cf',
            dark: '#797970'
        },
    },
});


const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
    },
    title: {
        flexGrow: 1,
    },
    contentContainer: {
        marginTop: '15px'
    }
}));


function App() {
    const classes = useStyles();

    return (
        <ThemeProvider theme={outerTheme}>
            <QueryClientProvider client={queryClient}>
                <AppBarFt/>
                <Container component="main" maxWidth="lg" className={classes.contentContainer}>
                
                    {/*A <Switch> looks through its children <Route>s and
                            renders the first one that matches the current URL. */}
                    <Switch>
                        <Route path={"/ft/:ft_id"} component={Report} />
                        <Route path="/accessibility"> <Accessibility/></Route>
                        <Route path="/"> <Richiesta/></Route>

                    </Switch>
                </Container>
                <ReactQueryDevtools></ReactQueryDevtools>
            </QueryClientProvider>
        </ThemeProvider>
    );
}

export default App;
