import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Scaduto from './expired.png';

const useStyles = makeStyles((theme) => ({
    center: {
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
        height: 300
    }
}));

export default function ReportScaduto({ ft_name }) {
    const classes = useStyles();

    return (
        <Grid container spacing={5} justifyContent="center">
            <Grid item xs={12}>

                <Typography variant="h3" align='center'>
                    {`Progetto: ${ft_name}`}
                </Typography>

            </Grid>
            <Grid item xs={12} >

                <img alt='Progetto Scaduto' src={Scaduto} className={classes.center}/>

            </Grid>
        </Grid>
    );
}
